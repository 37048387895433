
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { ElForm } from 'element-ui/types/form'
import { carrierMatch } from '@/utils/carrier'
import ZButton from '@/components/base/z-button.vue'

@Component({
	components: { ZButton },
	beforeRouteLeave(to: any, from: any, next: any) {
		this.$confirm('确认离开当前页面？', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		})
			.then(() => {
				next()
			})
			.catch(() => {
				next(false)
			})
	}
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
	}

	private inputCodeStr = ''
	private isLoading = false
	private codesList: any = []
	private editForm = {
		channel: ''
	}

	async parseCode() {
		if (!this.inputCodeStr.trim()) return ''
		const codes: string[] = Array.from(
			new Set(
				this.inputCodeStr
					.trim()
					.split(/[;,\s]/)
					.filter(item => {
						if (item.trim()) return item.trim()
					})
			)
		)
		this.inputCodeStr = ''
		const codesList = this.codesList.map((item: any) => item.code)
		const queryCodeRes = await this.queryCodes(codes)

		for (const code in queryCodeRes) {
			if (codesList.includes(code)) continue
			this.codesList.push({
				code,
				is_edited: false,
				is_loading: false,
				name_cn: '',
				message: '',
				...queryCodeRes[code]
			})
		}
	}
	emitRowInputCode($index: number, row: any, codeName: string) {
		const carrier = carrierMatch(row[codeName])
		if (carrier) {
			this.codesList[$index][codeName.replace(/code/, 'company')] =
				carrier.value
		}
	}

	formatUserRole(role: number) {
		if (role === 1) {
			return {
				label: '电商商家',
				type: 'success'
			}
		}
		if (role === 2) {
			return {
				label: '格布员工',
				type: 'danger'
			}
		}

		return {
			label: '个人客户',
			type: 'info'
		}
	}
	async queryCodes(codes: string[]) {
		const { data } = await this.$axios.post(
			'v1/jobline/ordering/package-code/search',
			{
				codes
			}
		)

		const codesMap: any = {}
		data.forEach((item: any) => {
			codesMap[item.code] = item
		})
		return codesMap
	}
	async removePackage({ row, $index }: any) {
		const isConfirm = await this.$confirm('确认删除？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		this.codesList.splice($index, 1)
	}

	async emptyPackages() {
		const isConfirm = await this.$confirm('确认清空？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		this.codesList = []
	}

	async doFetchPrintTime(row: any) {
		const { data } = await this.$axios
			.get('v1/jobline/tools/order/print-time', {
				params: {
					package_attr_num: row.code
				}
			})
			.catch(() => {
				return { data: null }
			})
			.finally(() => {
				row.is_loading = false
			})
		row.message = data
	}

	async doBatchPrintTime() {
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return
		const isConfirm = await this.$confirm('确认获取？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		await Promise.all(
			this.codesList.map(async (item: any) => {
				await this.doFetchPrintTime(item)
			})
		)
	}
}
