var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", { attrs: { to: { path: "/ops/tools" } } }, [
            _vm._v("工具"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("仓库打印面单时间")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("el-row", { attrs: { gutter: 30, span: 24 } }, [
            _c(
              "div",
              { staticClass: "container info-card" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "search-form",
                    attrs: { model: _vm.editForm, "label-width": "90px" },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return (() => {}).apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item-input",
                        attrs: { label: "包裹号/箱号", prop: "codes" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            placeholder: "多个单号用英文逗号 , 分隔",
                            rows: 2,
                          },
                          on: { blur: _vm.parseCode },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.parseCode.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.inputCodeStr,
                            callback: function ($$v) {
                              _vm.inputCodeStr = $$v
                            },
                            expression: "inputCodeStr",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    staticClass: "ordering-list",
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.codesList },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "code", label: "包裹单号", width: "150" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "express_title",
                        width: "150",
                        label: "线路",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "name", label: "客户类型", width: "90" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: _vm.formatUserRole(
                                      scope.row.user_role
                                    ).type,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatUserRole(scope.row.user_role)
                                        .label
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "包裹尺寸", width: "150px" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.length
                                ? _c("div", [
                                    _c("p", [
                                      _vm._v(
                                        " 尺寸：" +
                                          _vm._s(scope.row.length) +
                                          " * " +
                                          _vm._s(scope.row.width) +
                                          " * " +
                                          _vm._s(scope.row.height) +
                                          " "
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "重量： " +
                                          _vm._s(
                                            (scope.row.weight / 1000).toFixed(3)
                                          ) +
                                          "kg"
                                      ),
                                    ]),
                                  ])
                                : _c("div", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "末端承运商",
                        prop: "express_company",
                        width: "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.express_company
                                ? _c("div", [
                                    _c("p", [
                                      _vm._v(_vm._s(scope.row.express_company)),
                                    ]),
                                  ])
                                : _c("div", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "末端单号",
                        width: "250px",
                        prop: "express_code",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.express_code
                                ? _c("div", [
                                    _c("p", [
                                      _vm._v(_vm._s(scope.row.express_code)),
                                    ]),
                                  ])
                                : _c("div", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "message",
                        label: "面单打印时间(洛杉矶时区)",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(" " + _vm._s(scope.row.message) + " "),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", fixed: "right", width: "230" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button-group",
                                [
                                  _c(
                                    "z-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.doFetchPrintTime(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-refresh",
                                      }),
                                      _vm._v("重新查询"),
                                    ]
                                  ),
                                  _c(
                                    "z-button",
                                    {
                                      attrs: {
                                        type: "danger",
                                        size: "mini",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removePackage(scope)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-delete",
                                      }),
                                      _vm._v("删除"),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.codesList.length
            ? _c(
                "div",
                { staticClass: "ctrl-btn" },
                [
                  _c(
                    "z-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.isLoading,
                          expression: "isLoading",
                        },
                      ],
                      attrs: { type: "primary", disabled: _vm.isLoading },
                      on: { click: _vm.doBatchPrintTime },
                    },
                    [_vm._v(_vm._s(_vm.isLoading ? "获取中" : "立即获取"))]
                  ),
                  _c(
                    "z-button",
                    {
                      attrs: { type: "info" },
                      on: { click: _vm.emptyPackages },
                    },
                    [_vm._v("清空")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }